import {
  ThemeProvider,
  CssBaseline,
  Box,
  Typography,
  styled,
  Paper,
  Stack,
  Button,
  Container,
  Divider,
} from "@mui/material";
import { useLocation, Navigate } from "react-router-dom";
import { shadows } from "@mui/system";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import React, { useLayoutEffect, useEffect, useState } from "react";
import { fontSize, lineHeight } from "@mui/system";
import Voice from "screens/Home/Voice";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemText from "@mui/material/ListItemText";
import { requestApi } from "config/apiHandler";
import InstallAlert from "components/alert/InstallAlert";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [headingColor, setHeadingColor] = useState("black");
  const location = useLocation();
  useEffect(() => {
    if (location.state === "error") setHeadingColor("red");
  }, [location.state]);

  setTimeout(() => {
    setHeadingColor("black");
  }, 5000);
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
  }));

  function generate(element) {
    return [
      {
        label: "Gear Shift Lever",
        src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/fdf522c7eea44ee7a8219dd44151103f.jpg",
      },
      {
        label: "Keyless Push Start System",
        src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/f556ad4a764d46f0a4913382e2d20850.jpg",
      },
      {
        label: "Steering Wheel",
        src: "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/content/5a449f085c9744d9aa370bf1ea8953b8.jpg",
      },
    ].map((value, index) => (
      <ListItem key={index}>
        <ListItemAvatar>
          <Avatar alt={"icon"} src={value.src} />
        </ListItemAvatar>
        <ListItemText
          primary={value.label}
          secondary={false ? "Secondary text" : null}
        />
      </ListItem>
    ));
  }
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <Container fixed>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ padding: "10px", color: "#504f54" }}
        >
          S-Assist
        </Typography>
        <Divider />
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            padding: "5px",
            marginTop: "14px",
            textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
            color: headingColor,
          }}
        >
          {/* Please search the following features */}
          Supported Features
        </Typography>
        <Box>
          <List dense={true}>{generate()}</List>
        </Box>
        <Divider />
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            padding: "5px",
            marginTop: "14px",
            textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
          }}
        >
          Choose from below options
        </Typography>
        <Link to="/assist/camera" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              border: "1px dashed black",
              borderRadius: "2px",
              backgroundColor: "#fff",
              //   boxShadow: 2,

              padding: 2,
            }}
          >
            <Stack spacing={0}>
              <Box
                sx={{ textAlign: "center" }}
                onClick={() => {
                  //   <Navigate
                  //     to="/assist/camera"
                  //     replace
                  //     state={{ path: location.pathname }}
                  //   />;
                }}
              >
                <Button>
                  <CameraAltOutlinedIcon
                    variant="Outlined"
                    sx={{
                      fontSize: "80px",
                      textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
                      color: "black",
                    }}
                  />
                </Button>
              </Box>

              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{
                  fontSize: "16px",
                  textAlign: "center",
                  lineHeight: 1,
                  textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
                  color: "black",
                }}
              >
                Click image of car part to know more
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                sx={{
                  fontSize: "12px",
                  textAlign: "center",
                  margin: 0,
                  padding: 0,
                  color: "#c3c3c3",
                }}
              >
                (Like Steering Wheel Feature and more)
              </Typography>
            </Stack>
          </Box>
        </Link>
        <br />

        <Box
          sx={{
            border: "1px dashed black",
            borderRadius: "2px",
            backgroundColor: "#fff",
            //   boxShadow: 2,
            padding: 2,
          }}
        >
          <Stack spacing={0}>
            <Box
              sx={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              <Button>
                <ChatBubbleOutlineOutlinedIcon
                  variant="Outlined"
                  sx={{
                    fontSize: "80px",
                    textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
                    color: "black",
                  }}
                />
              </Button>
            </Box>
            {open ? <Voice handleClose={handleClose} /> : null}
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{
                fontSize: "16px",
                textAlign: "center",
                lineHeight: 1,
                textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
              }}
            >
              Talk to the Assistant for any queries
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{
                fontSize: "11px",
                textAlign: "center",
                margin: 0,
                padding: 0,
                color: "#c3c3c3",
              }}
            >
              (Like Gear Shift Lever, How to operate Gear Shift Lever and more)
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <a
            href={
              "https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/assist-demo/assets/NEXA_Owners_Manual.pdf"
            }
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button variant="text" sx={{ width: "100%", margin: 0 }}>
              <Item
                sx={{
                  borderRadius: 0,
                  // background: "#2e3494",
                  // color: "#cdcfe1",
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                View Owner's Manual
              </Item>
            </Button>
          </a>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <a
            href="http://onelink.to/xane"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="text"
              sx={{ width: "100%", margin: 0, textTransform: "none" }}
              onClick={() => {
                requestApi("/events", "POST", { event: "conversion" });
              }}
            >
              <Item
                sx={{
                  borderRadius: 0,
                  background: "#2e3494",
                  color: "#cdcfe1",
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={"S"}
                      src="https://xane-data.s3.ap-south-1.amazonaws.com/archives/assets/suzuki.webp"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ margin: "-2px" }}
                    primary="For Full Experience, Install Maruti Suzuki Rewards app."
                    secondary={false ? "Secondary text" : null}
                  />
                </ListItem>
              </Item>
            </Button>
          </a>
        </Box>

        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            padding: "5px",
            marginTop: "14px",
            textShadow: "0px 0px 2px rgba(150, 150, 150, 0.74)",
          }}
        >
          Disclaimer
        </Typography>
        {/* <Box> */}
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{
            fontSize: "12px",
            textAlign: "justify",
            padding: "5px",
            margin: 0,
            // padding: 0,
            marginBottom: "10px",
            color: "#c3c3c3",
          }}
        >
          The content shown in the application are only for reference purpose.
          For detailed information download MSR app.
        </Typography>
        {/* </Box> */}
      </Box>
    </Container>
  );
};

export default Home;
