import React from "react";
import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#f5f4f9",
  height: "100vh",

  flexGrow: 1,
};
export function Loader(props) {
  return (
    <div
      className="container-fluid"
      style={{ margin: 0, boxSizing: "border-box" }}
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            // variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[
                  theme.palette.mode === "light" ? "transparent" : 800
                ],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            // variant="indeterminate"
            // disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === "light" ? "#2e3494" : "#308fe8",
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={40}
            thickness={4}
            {...props}
          />
        </Box>
      </Box>
    </div>
  );
}
