import { lazy, Suspense } from "react";
import React from "react";
import { Loader } from "components/Loader";

import ErrorBoundary from "components/ErrorBoundary";

const Camera = lazy(() => import("screens/Home/Camera"));
const Home = lazy(() => import("screens/Home"));
const Voice = lazy(() => import("screens/Home/Voice.js"));
const Result = lazy(() => import("screens/Result"));
const Speechly = lazy(() => import("components/speechly.js"));

document.body.style.margin = 0;

const LazyCamera = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Camera {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyHome = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Home {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyVoice = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Voice {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyResult = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Result {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazySpeechly = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundary>
        <Speechly {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const appRoutes = [
  {
    path: "/camera",
    name: "camera",
    element: <LazyCamera />,
    layout: "assist",
  },
  {
    path: "/home",
    name: "home",
    element: <LazyHome />,
    layout: "assist",
  },
  {
    path: "/voice",
    name: "voice",
    element: <LazyVoice />,
    layout: "assist",
  },
  {
    path: "/result",
    name: "result",
    element: <LazyResult />,
    layout: "assist",
  },
  {
    path: "/test",
    name: "test",
    element: <LazySpeechly />,
    layout: "assist",
  },
];

export default appRoutes;
