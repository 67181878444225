import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  CssBaseline,
  createTheme,
  Container,
} from "@mui/material";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Cookies from "universal-cookie";
import { requestApi } from "config/apiHandler";
import appRoutes from "routes";
import Home from "screens/Home";
import { Loader } from "components/Loader";
import { theme } from "assets/theme";
import detectPlatform from "utils/platform";
import { ErrorAlerts } from "components/alert/alert";
import InstallAlert from "components/alert/InstallAlert";
import PWAPrompt from "react-ios-pwa-prompt";

const muiTheme = createTheme(theme);
function App() {
  const [loading, setLoading] = useState(false);
  const [showInstallAlert, setShowInstallAlert] = useState(true);

  const cookies = new Cookies();
  useEffect(() => {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);

    const getIdentity = async () => {
      setLoading(true);
      if (cookies.get("identity")) {
        setLoading(false);
      } else {
        let body = {
          platform: detectPlatform(),
        };
        const response = await requestApi("/identities", "POST", body);
        if (response.code === "success") {
          await cookies.set("identity", response.data.identity.identity, {
            expires: expireDate,
          });
          setLoading(false);
        } else {
          ErrorAlerts(response.message);
          setLoading(false);
        }
      }
    };
    getIdentity();
  }, []);

  const getRoutes = (appRoutes) => {
    return appRoutes.map((prop, key) => {
      return (
        <Route
          path={prop.layout + prop.path}
          element={prop.element}
          key={key}
        />
      );
    });
  };
  if (loading) {
    return <Loader />;
  }
  const handleInstallAlert = () => {
    setShowInstallAlert(false);
  };
  return (
    <div className="App">
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />

        {/* <InstallAlert handleInstallAlert={handleInstallAlert} /> */}
        <PWAPrompt />
        <Routes>
          <Route path="/" element={<Home />} />
          {getRoutes(appRoutes)}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
