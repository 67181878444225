import Swal from "sweetalert2";
import "components/alert/alerts.css";
export function ErrorAlerts(message) {
  let errorMessage = message.message ? message.message : message;
  Swal.fire({
    icon: "error",
    title: "Sorry",
    text: errorMessage,
    confirmButtonColor: "#2e3494",
    // footer: '<a href="">Why do I have this issue?</a>',
  });
}
export function UseSafariAlert(message) {
  // let errorMessage = message.message ? message.message : message;
  Swal.fire({
    // icon: "info",
    // title: "Sorry",
    // html:
    //   "Please Click " +
    //   '<a href="https://apps.apple.com/in/app/safari/id1146562112">Safari</a> ' +
    //   " for voice search",
    text: "Please use safari access voice search",
    confirmButtonColor: "#2e3494",
    showCancelButton: true,
    confirmButtonText:
      '<a style="color:white; text-decoration: none;" href="https://apps.apple.com/in/app/safari/id1146562112" target="_blank">Open Safari</a>',
    // footer: '<a href="">Why do I have this issue?</a>',
  });
}
