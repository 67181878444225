import Cookies from "universal-cookie";
const axios = require("axios");

let API_URL;
if (process.env.NODE_ENV === "production") {
  API_URL = "https://api.demo.assist.marutisuzuki.com/v1";
} else {
  API_URL = "https://api.demo.assist.marutisuzuki.com/dev";
}

const headers = {};
const cookies = new Cookies();
export const requestApi = async (resourcePath, method, params) => {
  let url = API_URL + resourcePath;
  if (resourcePath !== "/identities") {
    params.identity = cookies.get("identity");
  }
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data;
  }
};
