import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router";

import DialogContentText from "@mui/material/DialogContentText";
import { createSpeechlySpeechRecognition } from "@speechly/speech-recognition-polyfill";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button, Box, TextField, Icon } from "@mui/material";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SendIcon from "@mui/icons-material/Send";
import { requestApi } from "config/apiHandler";
import { UseSafariAlert } from "components/alert/alert";
import { detectIOS } from "utils/platform";

if (detectIOS()) {
  const appId = "9610e1c2-6373-4e83-ad3a-c026f3bcc709";
  const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
  SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Voice(props) {
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(true);
  // const [isSupported, setIsSupported] = React.useState(true);
  let navigate = useNavigate();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    setValue(transcript);
  }, [transcript]);
  // useEffect(() => {
  //   setIsSupported(browserSupportsSpeechRecognition);
  // }, [browserSupportsSpeechRecognition]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const setResponse = React.useCallback(() => {
    const res = { type: "text", value: value };

    navigate("/assist/result", { state: res });
  }, [value]);

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.nativeEvent.isComposing) {
        return;
      }

      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        setResponse();
      }
    },
    [setResponse]
  );
  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true });
  const handleStartListening = (e) => {
    // console.log(detectIOS());
    // if (detectIOS() || !browserSupportsSpeechRecognition) {
    //   UseSafariAlert();
    //   handleClose();
    //   // alert("Please use safari for voice search");
    //   return;
    // }
    resetTranscript();
    startListening();
  };
  // const handleClick = (e) => {
  //   if (!browserSupportsSpeechRecognition) {
  //     alert("Please use safari for voice search");
  //     // return;
  //   }
  //   e.preventDefault();
  // };
  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  // if (!browserSupportsSpeechRecognition) {
  //   setIsSupported()
  // }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle align="center">{"Talk to Assistant"}</DialogTitle>

        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Fab
              type="button"
              //   onClick={setResponse}
              onTouchStart={handleStartListening}
              onMouseDown={handleStartListening}
              onTouchEnd={handleStopListening}
              onMouseUp={handleStopListening}
              // onClick={handleClick}
              variant="contained"
              color="primary"
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                padding: "50px",
                backgroundColor: "#2e3494",
                color: "white",
              }}
            >
              <MicNoneIcon sx={{ fontSize: "45px" }} />
            </Fab>
          </Box>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{
              fontSize: "11px",
              textAlign: "center",
              margin: 0,
              padding: 0,
              color: "#c3c3c3",
            }}
          >
            Press and hold to mention the feature for which you want to see
            information
          </Typography>
          <Box
            sx={{
              //   border: "2px solid black",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <TextField
              value={value.toLowerCase()}
              //   value={value?value:transcript}
              onChange={(e) => setValue(e.target.value)}
              //   autoFocus
              //   multiline
              inputProps={{ onKeyDown: handleKeyDown }}
              variant="outlined"
              id="outlined-basic"
              label="Type query here"
              //   maxRows={10}
              sx={{ width: "100%" }}
            />
            &nbsp;&nbsp;
            <Button
              type="button"
              onClick={setResponse}
              disabled={!value && !transcript}
              variant="contained"
              color="primary"
              //   startIcon={}
              sx={{ fontSize: "10px", color: "#fff", margin: 0 }}
            >
              <SendIcon sx={{ fontSize: "30px" }} />
            </Button>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
export default Voice;
